// Dropdown
//
// A majority of menue dropdowns in the site are built from this base class.
//
// Markup:
// <button [cdkMenuTriggerFor]="profileDropdown" class="btn"> Dropdown </button>
// <ng-template #profileDropdown>
//   <div class="dropdown {{modifier_class}}" cdkMenu>
//     <a href="/" class="dropdown-item">Profile</a>
//     <a href="/" class="dropdown-item">Logout</a>
//   </div>
// </ng-template>
//
// dropdown-item      - Is used to style the dropdown items. Needs to be applied for every item of the dropdown.
// dropdown-left     - Can be used if the dropdown is positioned at the left, will add a rounded border on the right end
// dropdown-right     - Can be used if the dropdown is positioned at the right, will add a rounded border on the left end
//
// Style guide: Components.dropdown
.dropdown {
  min-width: 8rem;
  padding: 8px 0;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  overflow-x: hidden;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.129);
  border-radius: 4px;

  &-large {
    min-width: 13rem;
  }

  &-left {
    border-radius: 0px 0px 10px 0px;
  }

  &-right {
    border-radius: 0px 0px 0px 10px;
  }

  &-rounded {
    border-radius: 10px;
  }

  &-item {
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    border: 0;
    text-decoration: none;

    div {
      overflow: hidden;
    }

    cursor: pointer;

    .light-mode &:hover,
    .light-mode &:focus {
      background-color: #f2f2f2;
    }
    .dark-mode &:hover,
    .dark-mode &:focus {
      background-color: #111;
    }
  }
}
.dark-mode .dropdown {
  background-color: #212529;
}

.light-mode .dropdown {
  background-color: #FFF;
}
