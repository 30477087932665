@use 'node_modules/@ngxpert/hot-toast/src/styles/styles.scss';
// phosphor icons libraty import
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
//@use '@angular/material' as mat;
//@use '@angular/material-experimental' as matx;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//$remainmybox-primary: mat.define-palette(mat.$indigo-palette);
//$remainmybox-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
//$remainmybox-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
//$remainmybox-theme: mat.define-light-theme((
//  color: (
//    primary: $remainmybox-primary,
//    accent: $remainmybox-accent,
//    warn: $remainmybox-warn
//  )
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($remainmybox-theme);

// Tailwind CSS
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}
form {
  @apply flex flex-row flex-wrap gap-8;
}
input[type="checkbox"]:checked {
  @apply bg-blue border-blue border-2;
}

input[type="checkbox"]:not(:checked) {
  @apply bg-white border-blue border-2;
}

input[type="checkbox"]:hover {
  @apply border-blue;
}

input[type="checkbox"]:focus {
  outline: none;
  box-shadow: none;
}

// Place the text in the toasts left aligned, make the close button blue filled
.hot-toast-message-custom {
max-width: 400px;
box-shadow: -2px 10px 35px 0px rgba(0, 0, 0, 0.20);

  div {
    @apply justify-start font-text-m-semibold p-0;
  }

  button {
    background-image: var(--hot-toast-close-btn-background-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%231055d7ff'%3e%3cpath d='M3 3a1 1 0 011.41 0L12 10.59 19.59 3a1 1 0 011.41 1.41L13.41 12 21 19.59a1 1 0 01-1.41 1.41L12 13.41 4.41 21A1 1 0 013 19.59L10.59 12 3 4.41A1 1 0 013 3z'/%3e%3c/svg%3e"));
    background-size: 19px 19px; // Force the background image to be 24x24 pixels
    width: 24px; // Set the button width to 24px
    height: 24px; // Set the button height to 24px
    min-width: 24px; // Ensure minimum width
    min-height: 24px; // Ensure minimum height
    padding: 0; // Remove any padding
    background-position: center; // Center the background image
    background-repeat: no-repeat; // Prevent image repetition
    opacity: 1;
    margin-top: 0px;
  }

  button:hover,
  button:focus {
    @apply opacity-100;
  }

  p {
    @apply font-medium text-sm;
  }
}

.mat-mdc-tooltip-panel {
  .mat-mdc-tooltip {
    div {
      background-color: blue;
      @apply bg-blue-hover;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      text-align: left;
      padding: 8px 16px;
    }
    &::before {
      @apply border-blue-hover;
      border-style: solid;
    }
  }

  &-below {
    .mat-mdc-tooltip {
      &::before {
        left: 50%;
        right: 50%;
        margin-top: -18px;
        margin-left: -10px;

        bottom: 100%;
        border-top: 8px solid transparent;
        border-bottom-width: 8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }
    }
  }

  &-above {
    .mat-mdc-tooltip {
      &::before {
        top: 100%;
        left: 50%;
        right: 50%;
        margin-left: -10px;
        bottom: -10px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top-width: 8px;
        border-bottom: unset;
      }
    }
  }

  &-left {
    .mat-mdc-tooltip {
      &::before {
        top: 50%;
        bottom: 50%;
        left: 100%;
        margin-top: -7px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid transparent;
        border-left-width: 8px;
      }
    }
  }

  &-right {
    .mat-mdc-tooltip {
      &::before {
        top: 50%;
        bottom: 50%;
        left: -100%  !important;
        right: 100%;
        margin-top: -7px;

        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid transparent;
        border-right-width: 8px;
      }
    }
  }
}
