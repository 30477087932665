// Buttons
//
// A majority of buttons in the site are built from the same base class.
//
// Markup:
// <button class="btn {{modifier_class}}">Button Element</button>
//
// btn-primary   - Use this class to indicate that the button is the primary
//              feature of this form.
// btn-ghost     - Use this class to to make the button completly transparent except
//              it's content.
// :hover     - Highlight the button when hovered.
// :disabled  - Make the button change appearance to reflect it being disabled.
//
// Style guide: Components.buttons
.btn {
  display: inline-flex;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0;
  line-height: 1.5;
  border-radius: 0.25rem;

  &:hover {
    @apply text-blue bg-primary-light;
  }

  &:disabled {
    @apply bg-gray;
    cursor: default;
  }

  &-menu {
    margin: 0 4px;
    @apply bg-blue-light;
  }
}

.header__button {
  text-decoration: none;
  letter-spacing: 0.15em;
  @apply font-bold uppercase;
  display: inline-block;
  float: left;
  padding: 0.2rem 0 0 0;
}

.button-container:hover {
  cursor: pointer;
}

.button-container svg-icon {
  display: inline;
  float: left;
}
