// Links
//
// A majority of links in the site are built from the same base class.
//
// Markup:
// <a>Link</a>
//
// :hover     - Highlight the link when hovered.
//
// Style guide: Components.links

a:hover {
  text-decoration: underline;
  text-decoration-color: #429dff;
  color: #429dff;
}
